import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import APInput from 'apex-web/lib/components/common/APInput';
import APButton from 'apex-web/lib/components/common/APButton';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { required } from '../../helpers/formValidations';
import APPasswordInput from 'apex-web/lib/components/common/APPasswordInput/APPasswordInput';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';

import { defaultPath } from 'apex-web/lib/routeTemplates';
import path from '../../helpers/path';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import TradingServerComponent from 'apex-web/lib/components/TradingServer/TradingServerComponent';
import { styleNames } from '../../propTypes/commonComponent';
// import Big_X_Logo from "../../images/custom/Big_X_logo.svg";
// import Big_X_Logo from '../../images/custom/Big_X_logo_new.png';
import Logo from '../../images/custom/logo-white-cropped.png';
import '../../pages/css/Style.css';
import '../../pages/css/bootstrap.min.css';

// import '../../styles/components/common/StandaloneForm.css';
import './LoginFormComponent.css';

import { FaInfoCircle } from 'react-icons/fa';

var _slicedToArray = (function() {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;
    try {
      for (
        var _i = arr[Symbol.iterator](), _s;
        !(_n = (_s = _i.next()).done);
        _n = true
      ) {
        _arr.push(_s.value);
        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i['return']) _i['return']();
      } finally {
        if (_d) throw _e;
      }
    }
    return _arr;
  }
  return function(arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError(
        'Invalid attempt to destructure non-iterable instance'
      );
    }
  };
})();

var baseClasses = getBEMClasses('standalone-form');
var loginFormClasses = getBEMClasses('login-form');

export var LoginFormComponent = function LoginFormComponent(props, context) {
  // console.log(props.redirectLocation)
  let redirect_page = props.redirectLocation.pathname;
  let redirect_page_param = props.redirectLocation.search;
  console.log('Next page : ' + redirect_page);
  console.log('Next page param : ' + redirect_page_param);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const pwnu_email = urlParams.get('pwnu_email');
  const pwnu_redirect = urlParams.get('pwnu_redirect');
  console.log(pwnu_email);

  // const [showNotice, setShowNotice] = useState(true);

  useEffect(() => {
    // auto jump to external wallet page dua to new in-app wallet flow
    if (pwnu_email) {
      if (pwnu_redirect) {
        console.log('no pwnu redirect');
      } else {
        location.replace(
          'https://project1.green-x.io/wallet_page' +
            '?pwnu_email=' +
            pwnu_email
        );
      }
    }

    document.title = 'Green-X Login';

    // Set timer for system upgrade notice
    // const checkTime = () => {
    //   const currentTime = Math.floor(Date.now() / 1000);
    //   // Local Date and Time September 18, 2024 @ 3:00:00 am
    //   // UTC Date and Time September 17, 2024 @ 7:00:00 pm
    //   const endTime = 1726599600;
    //   setShowNotice(currentTime > endTime);
    // };
    // checkTime();
    // const intervalId = setInterval(checkTime, 1000);
    // return () => clearInterval(intervalId);
  }, []);

  var handleSubmit = props.handleSubmit,
    errorMsg = props.errorMsg,
    submitting = props.submitting,
    doSubmit = props.doSubmit,
    isConnected = props.isConnected,
    useEmailAsUsername = props.useEmailAsUsername,
    active = props.active,
    siteName = props.siteName,
    gateway = props.gateway,
    formGateway = props.formGateway,
    resetForm = props.resetForm;

  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    customGateway = _useState2[0],
    setCustomGateway = _useState2[1];

  if (formGateway === 'CUSTOM' && !customGateway) {
    setCustomGateway(true);
    resetForm('gateway');
  }

  // Custom title based on redirect page
  // [redirect path] : [custom title]
  const customTitleList = {
    '/GreenX-Presale-Order': 'GX Token Order Form',
    '/GreenX-Seed-Round-Order': 'GX Seed Round Order Form',
    '/UNTT-Presale-Order': '购买中质信云梯 (UNTT)'
  };
  const ShowCustomTitle = () => {
    var title = '';
    if (customTitleList[redirect_page]) {
      title = customTitleList[redirect_page];
    }
    return (
      <div className="d-flex flex-column m-auto justify-content-center my-3">
        <h1 className="text-center">{title}</h1>
      </div>
    );
  };

  const SystemNotice = () => {
    return React.createElement(
      'div',
      { className: 'system-notice' },
      React.createElement(
        'h3',
        {},
        React.createElement(FaInfoCircle, { className: 'me-3' }),
        context.t('Important Notice : ')
      ),
      React.createElement(
        'p',
        { style: { lineHeight: '1.3' } },
        context.t(
          'We will be performing a system upgrade on the following dates. '
        ),
        context.t(
          'Some customers may experience temporary service interruption. '
        ),
        // context.t(
        //   'Deposits and Withdrawal will be affected until further notice. '
        // ),
        context.t(
          'We apologize for any inconvenience and appreciate your patience.'
        )
      ),
      React.createElement('p', { className: 'mb-0' }, context.t('US Time:')),
      React.createElement(
        'p',
        { className: 'mb-0' },
        'Nov 27th, 07:00 pm - Nov 28th, 01:00 am (UTC)'
      ),
      React.createElement(
        'p',
        { className: 'mb-0 mt-4' },
        context.t('Hong Kong Time:')
      ),
      React.createElement(
        'p',
        { className: 'mb-0' },
        'Nov 28th, 03:00am - 09:00 am (HKT)'
      )
    );
  };

  return React.createElement(
    'div',
    {
      className: 'login-page'
    },
    React.createElement(
      'div',
      {
        className: 'row align-items-center'
      },
      React.createElement(
        'div',
        {
          className: 'col-lg-6 col-brand'
        },
        React.createElement('img', {
          src: Logo,
          className: 'login-logo'
        }),
        React.createElement(
          'a',
          {
            href: path('https://www.green-x.io/'),
            target: '_blank',
            className: 'visit-website-link'
          },
          context.t('Visit Our Website')
        ),
        React.createElement(ShowCustomTitle, { className: 'text-center' })
        // React.createElement(SystemNotice)
      ),
      React.createElement(
        'div',
        {
          className: 'col-lg-6 col-12 col-form'
        },
        React.createElement(
          'div',
          { className: 'mobile-logo' },
          React.createElement('img', {
            src: Logo,
            className: 'login-logo'
          })
        ),
        React.createElement(
          'div',
          {
            className: 'login-form-container'
          },
          React.createElement(
            'div',
            {
              className: 'mobile-custom-title text-center'
            },
            React.createElement(ShowCustomTitle)
            // React.createElement(SystemNotice, {
            //   className: 'system-notice-mobile'
            // })
          ),
          React.createElement(
            'div',
            {
              className: 'login__form__main'
            },
            React.createElement(
              'div',
              { className: 'login-signup-toggle' },
              React.createElement(
                Link,
                {
                  className: 'active',
                  to: '/login'
                },
                context.t('Log In')
              ),
              React.createElement(
                Link,
                {
                  to: '/signup'
                },
                context.t('Sign Up')
              )
            ),
            React.createElement(
              'form',
              {
                onSubmit: handleSubmit(function(values) {
                  return doSubmit(values, isConnected);
                }),
                className: ''
              },
              errorMsg &&
                React.createElement(
                  'p',
                  { className: 'ap-input__error' },
                  errorMsg
                ),
              React.createElement(APInput, {
                type: 'text',
                name: 'username',
                customClass: 'login-fields',
                // placeholder: context.t('Username'),
                // className:'form-group',
                label: useEmailAsUsername
                  ? context.t('Email')
                  : context.t('Username'),
                // validate: [required]
                is_prefill: pwnu_email == null ? false : true,
                pre_fill_value: pwnu_email,
                autoFocus: 'true'
              }),

              React.createElement(APPasswordInput, {
                type: 'password',
                name: 'password',
                customClass: 'login-fields',
                // placeholder: context.t('Password'),

                label: context.t('Password'),
                validate: [required]
              }),
              (!gateway || customGateway) &&
                React.createElement(APInput, {
                  type: 'text',
                  name: 'gateway',
                  customClass: '',
                  label: context.t('Gateway'),
                  validate: [required]
                }),
              !customGateway &&
                Array.isArray(gateway) &&
                React.createElement(TradingServerComponent, null),
              // React.createElement("hr", { className: "" }),
              React.createElement(
                APButton,
                {
                  type: 'submit',
                  disabled: submitting,
                  className: 'ap-button__btn login-btn mt-3',
                  customClass: '',
                  styleName: styleNames.additive
                },
                submitting ? context.t('Processing...') : context.t('Log In')
              )
            ),
            React.createElement(
              'div',
              { className: 'cant__login' },
              active &&
                React.createElement(
                  'ul',
                  { className: '' },
                  React.createElement(
                    'li',
                    { className: '' },
                    React.createElement(
                      Link,
                      {
                        to: path('/problem-logging-in'),
                        className: '',
                        disabled: true
                      },
                      context.t("Can't Login?")
                    )
                  )
                )
            )
          )
        )
      )
    )
  );
};

LoginFormComponent.defaultProps = {
  handleSubmit: function handleSubmit() {},
  submitting: false
};

LoginFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  referrer: PropTypes.string
};

LoginFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(LoginFormComponent, defaultPath.path);
