import React from 'react';
import PropTypes from 'prop-types';
// import PriceChartContainer from "apex-web/lib/components/PriceChart/PriceChartContainer.js";
import PriceChartContainer from './PriceChart/PriceChartContainer';
import DepthChartContainer from 'apex-web/lib/components/DepthChart/DepthChartContainer';
import config from 'apex-web/lib/config';
import TradingLayoutCharts1280Px from '../../layouts/Responsive/TradingLayout/TradingLayoutCharts1280Px';
// import TradingLayoutCharts1280Px from 'apex-web/lib/layouts/Responsive/TrandingLayout/TradingLayoutCharts1280Px';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import TradingViewWidget, {
  Themes,
  RangeTypes
} from 'react-tradingview-widget';

const tradingLayoutClasses = getBEMClasses('trading-layout');

function ErrorPage(props) {
  // The Bitstamp chart will conflict with AP Trading-View chart
  // only way to solve this is to add a reload when switching the charts.
  return location.reload();
  // return (
  //     <div className={"error-page"}>
  //         <div className={"oops"}>Oops!</div>
  //         <div className={"message"}>Something went wrong...</div>
  //     </div>
  // );
}

function StandardErrorBoundary(props) {
  React.Component.call(this, props);
  this.state = {
    hasError: false,
    error: undefined
  };
}
StandardErrorBoundary.prototype = Object.create(React.Component.prototype);
StandardErrorBoundary.getDerivedStateFromError = error => ({
  hasError: true,
  error: error
});
StandardErrorBoundary.prototype.componentDidCatch = function(error, errorInfo) {
  // console.log("Error caught!");
  // console.error(error);
  // console.error(errorInfo);
};
StandardErrorBoundary.prototype.render = function() {
  if (this.state.hasError) {
    return <ErrorPage />;
  } else {
    return this.props.children;
  }
};

let renderCharts = instrument => {
  if (
    instrument === 'BITSTAMP:AGWDUSD' ||
    instrument === 'BITSTAMP:CCTUSDT' ||
    instrument === 'BITSTAMP:HCSUSDT' ||
    instrument === 'BITSTAMP:TIRCUSDT' ||
    instrument === 'BITSTAMP:LQGOUSDT' ||
    instrument === 'BITSTAMP:2UTUSDT' ||
    instrument === 'BITSTAMP:DIGauUSDT' ||
    instrument === 'BITSTAMP:MAMIUSDT' ||
    instrument === 'BITSTAMP:MSTOUSDT' ||
    instrument === 'BITSTAMP:DIGauBTC' ||
    instrument === 'BITSTAMP:DIGauETH' ||
    instrument === 'BITSTAMP:U2UUSDT' ||
    instrument === 'BITSTAMP:IPGSUSDT' ||
    instrument === 'BITSTAMP:STMAUSDT' ||
    instrument === 'BITSTAMP:LTCUSDT' ||
    instrument === 'BITSTAMP:BCHUSDT' ||
    instrument === 'BITSTAMP:TS1USDT'
    // instrument === 'BITSTAMP:XRPUSDT' ||
    // for staging only
    // instrument === 'BITSTAMP:BTCUSD'
    // instrument === 'BITSTAMP:ETHUSDT'
  ) {
    return <PriceChartContainer chart={'TradingView'} />;
  } else {
    return (
      <StandardErrorBoundary>
        <TradingViewWidget
          symbol={instrument}
          //symbol={`BITSTAMP:BTCUSD`}
          locale="en"
          theme={Themes.DARK}
          range={RangeTypes.ALL}
          hide_legend={true}
          hide_side_toolbar={false}
          container_id="trading-view-chart"
          autosize
          // in testing
          // interval="D"
        />
      </StandardErrorBoundary>
    );
  }
};
renderCharts = (renderCharts => instrument => (
  <StandardErrorBoundary>{renderCharts(instrument)}</StandardErrorBoundary>
))(renderCharts);

const renderChartsMobile = instrument => {
  if (
    instrument === 'BITSTAMP:AGWDUSD' ||
    instrument === 'BITSTAMP:CCTUSDT' ||
    instrument === 'BITSTAMP:HCSUSDT' ||
    instrument === 'BITSTAMP:TIRCUSDT' ||
    instrument === 'BITSTAMP:LQGOUSDT' ||
    instrument === 'BITSTAMP:2UTUSDT' ||
    instrument === 'BITSTAMP:DIGauUSDT' ||
    instrument === 'BITSTAMP:MAMIUSDT' ||
    instrument === 'BITSTAMP:MSTOUSDT' ||
    instrument === 'BITSTAMP:DIGauBTC' ||
    instrument === 'BITSTAMP:DIGauETH' ||
    instrument === 'BITSTAMP:U2UUSDT' ||
    instrument === 'BITSTAMP:IPGSUSDT' ||
    instrument === 'BITSTAMP:STMAUSDT' ||
    instrument === 'BITSTAMP:LTCUSDT' ||
    instrument === 'BITSTAMP:BCHUSDT' ||
    instrument === 'BITSTAMP:TS1USDT'
  ) {
    return <PriceChartContainer chart={'TradingView'} />;
  } else {
    return (
      <StandardErrorBoundary>
        <TradingViewWidget
          symbol={instrument}
          //symbol={`BITSTAMP:BTCUSD`}
          locale="en"
          theme={Themes.DARK}
          range={RangeTypes.ALL}
          hide_legend={true}
          hide_side_toolbar={true}
          container_id="trading-view-chart"
          autosize
        />
      </StandardErrorBoundary>
    );
  }
};

class ExchangeCharts extends React.Component {
  renderCharts = () => {
    const props = {
      priceChart: PriceChartContainer,
      depthChart: DepthChartContainer,
      showDepthChart: config.TradingLayout.showDepthChart
    };

    if (this.props.width <= 1280) {
      return renderChartsMobile(this.props.symbol);
      //         <TradingViewWidget
      //           symbol={this.props.symbol}
      //           theme={Themes.LIGHT}
      //           range={RangeTypes.ALL}
      //           hide_legend={true}
      //           hide_side_toolbar={true}
      //           locale="en"
      //           container_id="trading-view-chart"
      //           autosize
      //         />
      //      <TradingLayoutCharts1280Px {...props} />;
    }

    return (
      <React.Fragment>
        {/*
        <div className={tradingLayoutClasses('chart-header')}>
          {this.context.t('Price Chart')}
      </div>*/}
        {renderCharts(this.props.symbol)}
        {/* 
        <TradingViewWidget
          symbol={this.props.symbol}
          //symbol={`BITSTAMP:BTCUSD`}
          locale="en"
          theme={Themes.LIGHT}
          range={RangeTypes.ALL}
          hide_legend={true}
          hide_side_toolbar={false}
          locale="en"
          container_id="trading-view-chart"
          autosize
        />
               <PriceChartContainer /> */}
        {/* {config.TradingLayout.showDepthChart && <DepthChartContainer />} */}
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.renderCharts()}</React.Fragment>;
  }
}

ExchangeCharts.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ExchangeCharts;
