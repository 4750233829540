import React from 'react';
import PropTypes from 'prop-types';
// import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import WalletDetailsBackButtonComponent from '../components/WalletDetails/WalletDetailsBackButtonComponent';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import BalancesMenuContainer from 'apex-web/lib/components/BalancesMenu/BalanceMenuContainer';
import WalletDetailsContainer from 'apex-web/lib/components/WalletDetails/WalletDetailsContainer';
import TransfersContainer from 'apex-web/lib/components/Transfers/TransfersContainer';
import TransfersFiltersContainer from 'apex-web/lib/components/Transfers/TransfersFilters';
import RecentActivityWithFilterContainer from 'apex-web/lib/components/RecentActivityWithFilter';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './ProductDetailsPage.css';
import { connect } from 'react-redux';
import { retrieveProductAttributes } from '../redux/action/productAction';

const layoutClasses = getBEMClasses('product-details-page');

const backPath = '/wallets';

const ProductDetailsPage = (props, context) => {
  React.useEffect(() => {
    if (props.products && props.products.length > 0) {
      retrieveProductAttributes(props.products);
    }
  }, []);

  return (
    <React.Fragment>
      <div className={layoutClasses('container')}>
        <div className={layoutClasses('menu')}>
          <WalletDetailsBackButtonComponent key="1" path={backPath} />
          <BalancesMenuContainer key="2" />
        </div>
        <div className={layoutClasses('content')}>
          <div className={layoutClasses('wallet-details')}>
            <WalletDetailsContainer />
          </div>
          <RecentActivityWithFilterContainer filterForSelected={true} />
          <div className={layoutClasses('transfers')}>
            <div className={layoutClasses('transfers-header')}>
              {context.t('Transfers')}
            </div>
            <TransfersFiltersContainer />
            <TransfersContainer />
          </div>
        </div>
      </div>
      <div className={layoutClasses('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

ProductDetailsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    products: state.product.products
  };
};
function compose(...morphisms) {
  let morphism = o => o;
  for (let m in morphisms) {
    let _morphism = morphism;
    morphism = o => m(_morphism(o));
  }
  morphism = morphisms[0];
  return morphism;
}
export default compose(
  connect(mapStateToProps),
  withAuthentication
)(ProductDetailsPage);
