import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import I18n from 'redux-i18n';
import history from './helpers/history';
import * as numberFormatter from 'apex-web/lib/helpers/numberFormatter';
import initAPEXWebActions from 'apex-web/lib/helpers/apexWebActions';
import { unregister } from './registerServiceWorker';
import config from './config';
import cssVars from 'css-vars-ponyfill';

import './styles/base/Fonts.css';
import './styles/base/Base.css';
import './styles/theme/variables.css';
import './styles/components/common/Divider.css';
import './styles/layout/Table.css';
import './styles/layout/app.css';
import './styles/components/common/index.css';

import('./config').then(config =>
  import(`./styles/theme/${config.default.global.theme}.css`)
);
import './styles/theme/customv2.css';

import App from './App';
import store from './redux/store';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import en_file from './locales/en/common.json';
import zh_file from './locales/zh/common.json';
import ko_file from './locales/ko/common.json';
import id_file from './locales/id/common.json';
import ja_file from './locales/ja/common.json';
import './i18n';
initAPEXWebActions();

numberFormatter.init({
  locale: config.global.locale,
  getDecimalPlaces: () => store.getState().product.decimalPlaces
});

window.cssVariables = {};
cssVars({
  watch: true,
  silent: true,
  onComplete: function(cssText, styleNode, cssVariables) {
    window.cssVariables = cssVariables;
  }
});

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: {
      common: en_file // 'common' is our custom namespace
    },
    zh: {
      common: zh_file
    },
    ko: {
      common: ko_file
    },
    id: {
      common: id_file
    },
    ja: {
      common: ja_file
    }
  }
});

const language = window.location.href.split('/').pop();
if (language === 'zh') {
  window.localStorage.setItem('language', 'zh');
} else if (language === 'en') {
  window.localStorage.setItem('language', 'en');
} else if (language === 'ko') {
  window.localStorage.setItem('language', 'ko');
} else if (language === 'id') {
  window.localStorage.setItem('language', 'id');
} else if (language === 'ja') {
  window.localStorage.setItem('language', 'ja');
}

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <Router history={history}>
        <I18n translations={window.APEXTranslations || { en: {} }}>
          <App />
        </I18n>
      </Router>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

unregister();
