import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';
import SendReceiveConfirmHeaderComponent from './SendReceiveConfirmHeaderComponent';

import './SendReceiveConfirmModal.css';

var bemClasses = getBEMClasses('send-receive-confirm-modal');

var SendReceiveConfirmModal = function SendReceiveConfirmModal(props, context) {
  var isOpen = props.isOpen,
    close = props.close,
    product = props.product,
    isSend = props.isSend,
    values = props.values,
    onConfirm = props.onConfirm,
    useExternalAddress = props.useExternalAddress,
    fee = props.fee,
    total = props.total,
    hideFees = props.hideFees;

  var addressLabel = context.t('External Address');
  var sendLabel = context.t('Recipient’s Email');
  var receiveLabel = context.t('Requestee’s Email');

  return React.createElement(
    Modal,
    {
      isOpen: isOpen,
      title: React.createElement(SendReceiveConfirmHeaderComponent, {
        isSend: isSend,
        product: product
      }),
      close: close,
      footer: {
        buttonText: context.t('Confirm'),
        buttonStyle: 'additive',
        onClick: onConfirm
      },
      customClass: bemClasses()
    },
    React.createElement(
      'header',
      { className: bemClasses('title') },
      context.t('Confirmation')
    ),
    React.createElement(
      'section',
      { className: bemClasses('details') },
      !useExternalAddress &&
        React.createElement(APLabelWithText, {
          label: isSend ? sendLabel : receiveLabel,
          text: values.ReceiverUsername,
          customClass: bemClasses()
        }),
      React.createElement(APLabelWithText, {
        label:
          (product.Product || product.ProductSymbol) +
          ' ' +
          context.t('Amount'),
        text:
          formatNumberToLocale(
            values.Amount,
            product.Product || product.ProductSymbol
          ) +
          ' ' +
          (product.Product || product.ProductSymbol),
        customClass: bemClasses()
      }),
      useExternalAddress &&
        React.createElement(
          React.Fragment,
          null,
          !hideFees &&
            React.createElement(
              React.Fragment,
              null,
              React.createElement(APLabelWithText, {
                label: context.t('Miner Fees'),
                text:
                  formatNumberToLocale(
                    fee,
                    product.Product || product.ProductSymbol
                  ) +
                  ' ' +
                  (product.Product || product.ProductSymbol),
                customClass: bemClasses()
              }),
              React.createElement(APLabelWithText, {
                label:
                  product.Product ||
                  product.ProductSymbol + ' ' + context.t('Total Amount'),
                text:
                  formatNumberToLocale(
                    total,
                    product.Product || product.ProductSymbol
                  ) +
                  ' ' +
                  (product.Product || product.ProductSymbol),
                customClass: bemClasses()
              })
            ),
          React.createElement(APLabelWithText, {
            label: addressLabel,
            text: values.ExternalAddress,
            customClass: bemClasses()
          })
        ),
      !useExternalAddress &&
        React.createElement(APLabelWithText, {
          label: context.t('Note'),
          text: values.Notes,
          customClass: bemClasses()
        }),
      React.createElement(APLabelWithText, {
        label: context.t('Time'),
        text: formatDateTime(new Date()),
        customClass: bemClasses()
      })
    )
  );
};

SendReceiveConfirmModal.propTypes = {
  close: PropTypes.func.isRequired,
  isSend: PropTypes.bool.isRequired,
  fee: PropTypes.number.isRequired,
  total: PropTypes.number,
  useExternalAddress: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired
};

SendReceiveConfirmModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendReceiveConfirmModal;
