var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

import React from 'react';
import PropTypes from 'prop-types';
import SidePaneDetailsComponent from 'apex-web/lib/components/common/SidePaneDetailsComponent/SidePaneDetailsComponent';
import { formatNumberToLocale } from 'apex-web/lib/helpers/numberFormatter';

var SendReceiveDetailsComponent = (function(_React$Component) {
  _inherits(SendReceiveDetailsComponent, _React$Component);

  function SendReceiveDetailsComponent() {
    _classCallCheck(this, SendReceiveDetailsComponent);

    return _possibleConstructorReturn(
      this,
      (
        SendReceiveDetailsComponent.__proto__ ||
        Object.getPrototypeOf(SendReceiveDetailsComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(SendReceiveDetailsComponent, [
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          isSend = _props.isSend,
          useExternalAddress = _props.useExternalAddress,
          balance = _props.balance,
          Amount = _props.details.Amount,
          ProductSymbol = _props.product.ProductSymbol,
          TicketAmount = _props.TicketAmount,
          fee = _props.fee,
          _feeChargedString = _props.product.feeCharged;
        var getItem = SidePaneDetailsComponent.getItem;

        var title = isSend
          ? this.context.t('Send Details')
          : this.context.t('Instructions');
        var amountText = this.context.t('Amount to Send');

        var remainingBalance = formatNumberToLocale(
          balance - (useExternalAddress ? TicketAmount + fee : Amount),
          ProductSymbol
        );
        var classModifiers = isSend ? 'top' : '';

        var items = [];
        var info = [];
        var infoHeader = '';

        // var feeChargedString = _feeChargedString.percentage && _feeChargedString.flatRate ?
        //                       '( ' + _feeChargedString.percentage + '% + '
        //                            + this.context.t('Flat Rate') + ': ' + _feeChargedString.flatRate +
        //                       ' )'
        //                       : '';

        // Change fee based on Philip's request
        var feeChargedString = ` (${_feeChargedString.percentage}%)`;
        var feeChargedText = this.context.t('Fee') + feeChargedString;

        if (isSend) {
          items.push(
            getItem(
              this.context.t('Your current {ProductSymbol} Balance', {
                ProductSymbol: ProductSymbol
              }),
              balance
                ? formatNumberToLocale(balance, ProductSymbol) +
                  ' ' +
                  ProductSymbol
                : '-'
            )
          );
          items.push(
            getItem(
              amountText,
              Amount
                ? formatNumberToLocale(
                    useExternalAddress ? TicketAmount : Amount,
                    ProductSymbol
                  ) +
                  ' ' +
                  ProductSymbol
                : '-'
            )
          );
          items.push(
            getItem(
              useExternalAddress ? feeChargedText : this.context.t('Fee'),
              useExternalAddress
                ? formatNumberToLocale(fee, ProductSymbol) + ' ' + ProductSymbol
                : '-'
            )
          );
          items.push(
            getItem(
              this.context.t('Remaining Balance'),
              Amount && balance ? remainingBalance + ' ' + ProductSymbol : '-'
            )
          );
        }

        if (isSend && !useExternalAddress) {
          infoHeader = this.context.t('Note');
          info.push(
            this.context.t(
              'If the sender is not a registered user of Retail, they will receive an email invitation to create an account and claim their funds.'
            )
          );
        }

        if (!isSend && !useExternalAddress) {
          info.push(
            this.context.t('Use this form to request funds via email.')
          );
          info.push(
            this.context.t(
              'Note - if the requestee is not a registered user of Retail, they will receive an email invitation to create an account.'
            )
          );
        }
        if (!isSend && useExternalAddress) {
          info.push(
            this.context.t(
              'Depositing cryptocurrency into your wallet is safe and easy. The address below can always be used to deposit cryptocurrency into your account.'
            )
          );
          info.push(
            this.context.t(
              'Your account will automatically update after the cryptocurrency network confirms your transaction. The confirmation may take up to 1 hour'
            )
          );
        }

        return React.createElement(SidePaneDetailsComponent, {
          title: title,
          items: items,
          info: info,
          infoHeader: infoHeader,
          classModifiers: classModifiers
        });
      }
    }
  ]);

  return SendReceiveDetailsComponent;
})(React.Component);

SendReceiveDetailsComponent.propTypes = {
  isSend: PropTypes.bool,
  useExternalAddress: PropTypes.bool,
  details: PropTypes.object,
  fee: PropTypes.number,
  total: PropTypes.number
};

SendReceiveDetailsComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendReceiveDetailsComponent;
